export const data = [
     "Profile Summary",
     "Academic and Co-curricular Activities",
     "Summer Internship Experience",
     "Work Experience",
     "Projects",
     "Certifications",
     "Leadership Positions",
     "Extracurricular",
     "Education"
]